// Customizable Area Start
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Toolbar from "@material-ui/core/Toolbar";
import {TextField, Typography,Badge} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from '@material-ui/core/styles';
import { Button} from '@material-ui/core'
import { Link } from "react-router-dom";
//@ts-ignore
import Cookies from 'js-cookie';
const notificatioBell = require("../assets/Notification.png").default;
const UnreadNotificatioBell = require("../assets/UnreadNotifIcon.png").default;
const translate = require("../assets/translate.png").default;
const searchIcon = require('../../../components/src/searchIcon.png').default;
const Cart = require("../assets/Cart.png").default;
//@ts-ignore
import serachIcon from '../../../components/src/serachIcon.png';
//@ts-ignore
import profileImage from "../assets/profile_dummy.png"
import "react-toastify/dist/ReactToastify.css";
import '../assets/BuyerNavbar.scss'
import BuyerNavbarController, {Props} from "./BuyerNavbarController.web";
import LogOutModalWeb from "./LogOutModalWeb.web";
import {Language} from "../../../framework/src/Translation"
import { translate as t } from "../../../framework/src/Translation";
import LogoWithText from "./LogoWithText";


const styles = (() => ({
    noBorder: {
      border: "none"
    },
  }));

class BuyerNavbar extends BuyerNavbarController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <div>
                <Box
                    //@ts-ignore
                    sx={{ flexGrow: 1 }}
                >
                    <AppBar position="static" style={{ background: "#f8fafb" }}>
                        <div className="nav-div">                          
                            <LogoWithText isHeader={true}/>
                            <div className="search-tab">
                                <TextField
                                    id="searchbar"
                                    className="search-input"
                                    // style={{ width: "32rem" }}
                                    placeholder={t("search_products")}
                                    variant="outlined"
                                    value={this.state.searchValue}
                                    onChange={(e) => {
                                        this.searchProd(e)
                                    }}
                                    onKeyPress={(e)=>{
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            this.setState({
                                                isSearchModalOpen:false
                                            })
                                            //@ts-ignore
                                            this.props.history.push(`/all-products?search=${this.state.searchValue}`)
                                          }
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <img src={serachIcon} alt="search icon" />
                                        ),
                                        //@ts-ignore
                                        classes:{notchedOutline:this.props.classes.noBorder}
                                    }}
                                />
                                {this.state.isSearchModalOpen && 
                                    <div className="search_modal" ref={this.setWrapperRef}>
                                        {this.state.searchSuggestions===null && (
                                            <div className="search_suggestion_err">
                                                <Typography className="suggestion_text">No Product Found</Typography>
                                            </div>
                                        )}
                                        {this.state.searchSuggestions?.map((data:any,id:any)=>{
                                            if(!data.is_featured){
                                                return (
                                                    <div className="search_suggestion_cont" key={id} onClick={()=>{
                                                        //@ts-ignore
                                                        this.props.history.push(`/product-details/${data.id}`)
                                                        location.reload();
                                                    }}>
                                                        <img src={searchIcon} alt="search icon" />
                                                        <Typography className="suggestion_text" 
                                                        >{data.name}</Typography>
                                                    </div>
                                                )
                                            }
                                            else{
                                                return (
                                                    <div className="search_suggestion_cont"
                                                    key={id} onClick={()=>{
                                                        //@ts-ignore
                                                        this.props.history.push(`/product-details/${data.id}`)
                                                        location.reload();
                                                    }}>
                                                    <div>
                                                        <img src={data.image} alt="" width={'40px'} height={'40px'} style={{borderRadius:'6px'}}/>
                                                    </div>
                                                    <div style={{marginLeft:'10px'}}>
                                                        <Typography className="suggestion_feat_prod_text">
                                                            {data.name}
                                                        </Typography>
                                                        <Typography className="suggestion_feat_cat_text">
                                                            {data.category}
                                                        </Typography>
                                                    </div>
                                                </div>
                                                )
                                            }
                                        })}
                                    </div>
                                }
                            </div>

                            <Toolbar>
                                {localStorage.getItem('user_type')==="buyer"?
                                Cookies?.get('Login_Token') && <Link style={{
                                                padding: "0px 0px",
                                                cursor: "pointer",
                                                position:'relative',
                                                // width: '100%'
                                            }} to='/cart'>
                                    <Tooltip title="Cart">
                                        <img
                                            src={Cart}
                                            alt=""
                                            style={{
                                                padding: "0px 10px",
                                                cursor: "pointer",
                                                minWidth:'5px !important'
                                            }}
                                        />
                                    </Tooltip>
                                        {this.state.cartCount !==0 && <div className="cartCountCon">
                                            <p className="cartCount">
                                            {this.state.cartCount}
                                            </p>
                                        </div>}
                                </Link>:null}
                                <Box
                                    //@ts-ignore
                                    sx={{ minWidth: 120 }}
                                >
                                    <div className="language-container">
                                    <Tooltip title="Language">
                                        <img src={translate} alt="language" />
                                        </Tooltip>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={this.state.language}
                                            disableUnderline
                                            //@ts-ignore
                                            onChange={(e) => this.handleLanguageChange(e.target.value as Language)}
                                        >
                                            <MenuItem value="en">
                                            {t("English")}
                                            </MenuItem>
                                            <MenuItem value="hi">
                                            {t("Hindi")}
                                            </MenuItem>
                                            <MenuItem value="kn">
                                            {t("Kannada")}
                                            </MenuItem>
                                        </Select>
                                    </div>
                                </Box>
                                <Tooltip title="Notifications">
                                    <div className="notification-space">
                                    <Badge color="secondary" variant="dot" invisible={this.state.IsNotifRead}>
                                    <img
                                        onClick={() => {
                                            //@ts-ignore
                                            this.props?.history?.push({
                                                pathname: "/notifications",
                                            });
                                        }}
                                        src={notificatioBell}
                                        alt=""
                                        style={{
                                            // padding: "0px 16px 0px 5px",
                                            cursor: "pointer",
                                        }}
                                    />
                                    </Badge>
                                    </div>
                                </Tooltip>
                                {Cookies.get('Login_Token') &&
                                <><div
                                    className="div-profile"
                                    style={{ color: "black" }}
                                    onClick={() => {
                                        this.setState({
                                            dropOpen: !this.state.dropOpen
                                        })
                                    }}
                                >
                                    <img
                                        className="profile-img"
                                        src={this.state.userDetails[0]?.avatar ||
                                            profileImage
                                        }
                                        alt="user profile image"
                                    />
                                    &nbsp;&nbsp;
                                    <div className="profile-line">
                                        <h4 className="user-line">
                                            {this.state.userDetails[0]?.fullname}
                                        </h4>
                                        <p className="account-line">
                                            {localStorage.getItem('user_type')}
                                        </p>
                                    </div>
                                    {this.state.dropOpen && (
                                    localStorage.getItem('user_type') === 'buyer' ? (
                                        <>
                                        <div className="overlay2"></div>
                                        <div className="profile-dropdown">
                                        <div className="dropdown-tab" onClick={()=>{
                                            //@ts-ignore
                                            this.props.history.push('/account/profile')
                                        }}><p className="dropdown-text">{t("profile")}</p></div>
                                        <div className="dropdown-tab" onClick={()=>{
                                            //@ts-ignore
                                            this.props.history.push('/account/addresses')
                                        }}><p className="dropdown-text">{t("address")}</p></div>
                                        <div className="dropdown-tab" onClick={()=>{
                                            //@ts-ignore
                                            this.props.history.push('/account/my-orders')
                                        }}><p className="dropdown-text">{t("orders")}</p></div>
                                        <div className="dropdown-tab" onClick={()=>{
                                            //@ts-ignore
                                            this.props.history.push('/account/change-password')
                                        }}><p className="dropdown-text">{t("change_password")}</p></div>
                                        <div className="dropdown-tab" onClick={()=>{
                                            //@ts-ignore
                                            this.props.history.push('/account/referrals')
                                        }}><p className="dropdown-text">{t("referrals")}</p></div>
                                        <div className="dropdown-tab" onClick={()=>{
                                            this.setState({
                                                dropOpen: false,
                                                showLogoutModal: true
                                            })
                                        }}><p className="dropdown-text logout-text">{t("logout")}</p></div>
                                    </div>
                                    </>
                                    ) : localStorage.getItem('user_type') === 'merchant' ? (
                                        <>
                                        <div className="overlay2"></div>
                                        <div className="profile-dropdown">
                                        <div className="dropdown-tab" onClick={()=>{
                                            //@ts-ignore
                                            this.props.history.push('/profile')
                                        }}><p className="dropdown-text">{t("profile")}</p></div>
                                        <div className="dropdown-tab" onClick={()=>{
                                            this.setState({
                                                dropOpen: false,
                                                showLogoutModal: true
                                            })
                                        }}><p className="dropdown-text logout-text">{t("logout")}</p></div>
                                    </div>
                                    </>
                                    ): null
                                    )}
                                </div>
                                </>}
                                {!Cookies?.get('Login_Token') &&
                                    <div>
                                        <Link to="/login">
                                            <Button className="login_button">Login / Signup</Button>
                                        </Link>
                                    </div>
                                }
                            </Toolbar>
                            {/* </div> */}
                        </div>
                    </AppBar>
                </Box>
                <Box className="cat-container">
                    {this?.state?.categoriesList?.map((category:any, index:any) =>
                        <div key={index} className="cat-listing" onClick={()=>{
                            //@ts-ignore
                            this.props.history.push({pathname:`/products/${'category'}`,state:{id:category.attributes.id, name:this.getCategoryName(category)}})
                        }}>
                            {this.getCategoryName(category)}
                        </div>
                    )}
                </Box>
                <LogOutModalWeb
                  //@ts-ignore
                  openLogoutModal={this.state.showLogoutModal} closeLogoutModal={() => this.setState({showLogoutModal: false})} history={this.props.history} />
            </div>
        );
    }
}
//@ts-ignore
export default withStyles(styles)(withRouter(BuyerNavbar));
// Customizable Area End
