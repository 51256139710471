import React from "react";
import { CartLogo } from "./assets";

interface LogoWithTextProps {
  isHeader?: boolean; 
}

const LogoWithText: React.FC<LogoWithTextProps> = ({ isHeader }) => {
  const handleClick = () => {
    const userType = localStorage.getItem("user_type");
    if (userType === "merchant") {
      window.location.href = "/dashboard"; 
      window.location.href = "/";
    }
  };

  return (
    <div
      style={{ display: "flex", alignItems: "center", cursor: isHeader ? "pointer" : "default" }}
      onClick={isHeader ? handleClick : undefined} 
    >
      <div style={{ marginRight: "5px" }}>
        <img src={CartLogo} alt="logo" style={{ width: "50px" }} />
      </div>
      <div>
        <h1
          style={{
            fontFamily: "Roboto, sans-serif",
            fontWeight: 600,
            fontSize: "24px",
            color: "#131313",
          }}
        >
          Growwer
        </h1>
      </div>
    </div>
  );
};

export default LogoWithText;